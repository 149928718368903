.image{
    width:100px;
    height:100px;
}
.image-match{
    height:100px;
}
.iconContent{
    margin-left:30px;
    margin-top: 20px;
    font-weight: bold;
    font-size:10px;
}
.distance{
    position:absolute;
    top:10px;
    left:10px;
    color: black;
    font-size:16px;
    z-index: 99;
}
.map{
    position: relative;
    width:100%;
    height:500px;
}
