.h{
  margin-top: 20px;
  margin-bottom:20px;
}
.p{
  margin-bottom:20px;
}
.bigFont{
  font-size:20px;
}
.accountItem{
  margin-bottom:10px;
}
.spacer{
  height:50px;
}
.imageBig{
  width:100%;
  height:auto;
}
.imageSmall{
  max-width:100%;
  height:auto;
  margin-bottom:20px;
}
.first{
  display:flex;
  justify-content: space-between;
  margin-bottom:20px;
}
.auth-container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
