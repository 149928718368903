.image{
    width:100px;
    height:100px;
}
.image-match{
    height:100px;
}
.iconContent{
    margin-left:30px;
    margin-top: 20px;
    font-weight: bold;
    font-size:10px;
}
